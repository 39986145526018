<template>
  <div>
    <div id="container"></div>
</div>
</template>

<script>
// 引入eventBus
import bus from "../../../eventBus/eventBus";
// 引入地图
import AMapLoader from '@amap/amap-jsapi-loader';
import { mapActions, mapGetters,mapState,mapMutations } from 'vuex';
window._AMapSecurityConfig = {
  securityJsCode: 'd648ce4940b608f7d5a396d45a8c73c5'
}
export default {
  props:['isMap'],

  data() {
    return {
      map:null,//初始化地图数据
      autoOptions: {
        input: '',//用于接收input的id对象
      },
      auto: null,//用于进行输入提示的接收变量
      placeSearch:null,//用于进行poi接收变量
      searchPlaceInput: '',//用于接收输入框地址
      placeName:'',//定义返回的地址
      placeCode:'',//地址的经纬度
      geocoder :[],//用于正向地理编码的接收

    };
  },
  computed:{
    ...mapState('customerInformation',['form'])
  }, 
  mounted() {
    this.initMap()
  },
  created(){
    bus.$on('shareUserInput',val=>{
      // console.log(val);
      this.autoOptions.input = val.inputId
    }),
    bus.$on('share',val=>{
      this.searchPlaceInput = val;
    })
  },
  methods: {
    // 选择地点方法
    select(e) {
      this.placeSearch.setCity(e.poi.adcode)
      this.placeSearch.search(e.poi.name) //关键字查询查询
      this.geocoder.getLocation(e.poi.name,(status, result)=>{
        if(status === 'complete'){
          this.geocoder = [...result.geocodes[0].location.pos];//获取经纬度
          this.placeName = e.poi.name
          this.$emit('getlocations',[ this.geocoder,this.placeName ])
        }
      })
    },

    initMap(){
        AMapLoader.load({
            key:"99182771af4c99485000ade6668c122d",// 申请好的Web端开发者Key，首次调用 load 时必填
            version:"2.0",      // 指定要加载的 JSAPI 的版本
            plugins:['AMap.Scale','AMap.ToolBar','AMap.HawkEye','AMap.MapType','AMap.Geolocation','AMap.AutoComplete','AMap.PlaceSearch','AMap.Geocoder'],// 需要使用的的插件列表，
        }).then((AMap)=>{
            this.map = new AMap.Map("container",{  //设置地图容器id
                viewMode: "2D",
                zoom:12,  //初始化地图级别
                resizeEnable: true,
                //center:[116.397428, 39.90923], //初始化地图中心点位置
            });
            // 使用地图控件
            // 搜索控件
            this.auto = new AMap.AutoComplete(this.autoOptions);
            this.placeSearch = new AMap.PlaceSearch({
              map: this.map
                }) //构造地点查询类
            this.geocoder = new AMap.Geocoder({})
            this.auto.on('select', this.select)

            this.map.addControl(new AMap.Scale());//地图的比例尺控件
            this.map.addControl(new AMap.ToolBar());//地图缩放控件
            this.map.addControl(new AMap.Geolocation(
              {
                position:{
                  bottom:'90px',
                  right:'19px'
                },
              }
            ));
        }).catch(e => {
          console.log(e)
        })
    },
  },
};
</script>

<style scoped>
#container {
  width: 100%;
  height: 500px;
}
.map_btn{
  position: absolute;
  width: 20px;
  height: 20px;
  top: 8px;
  left: 5px;
  cursor: pointer;
}

</style>