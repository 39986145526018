<template>
  <div id="box">
    <input type="text" placeholder="请输入你要查找的地址" :id="inputObj.inputId" v-model="inputObj.userIpt">
    <div class="btns">
      <button id="searchBtn"  @click="sendMsg">
        <i class="el-icon-search"></i>
      </button>
    </div>
  </div>
</template>

<script>
// 引入eventBus 
import bus from "../../../eventBus/eventBus.js";
export default {

  data() {
    return {
      inputObj:{
        userIpt:'',//用户的输入
        inputId:'searchInput',//输入框id
      },
    };
  },

  mounted() {
    this.send()
  },

  methods: {
    //将userIpt和inputId传给map.vue
    send(){
      bus.$emit('shareUserInput',this.inputObj);
    },
    // 将输入框中的地址传给map.vue
    sendMsg() {
      bus.$emit('share', this.inputObj.userIpt);
      // 将地址传给buildNewUser
      this.$emit('shareLoc',this.inputObj.userIpt)
    },
  },
};
</script>

<style scoped>
#box{
  width: 280px;
  height: 40px;
  display: flex;
  /* border: 1px solid #333; */
  border-radius:5px 5px 5px 5px;
  border: 1px solid #fff;
}
#searchInput{
  width: 224px;
  height: 40px;
  background-color: #fff;
  padding-left: 10px;
}
#searchInput ::-webkit-input-placeholder{
  font-family: 'SHSCN_Regular';
}
#box .btns{
  width: 54px;
  height: 40px;
  background-color: #0666F5;
  border-radius:0px 5px 5px 0px;
  text-align: center;
  line-height: 40px;
}
.btns i{
  font-size: 20px;
  color: #fff;
}
</style>